<template>
  <div class="type-management-container">
    <vab-query-form>
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <!-- <el-form-item>
            <el-select v-model="queryForm.articleCategoryId" clearable>
              <template v-for="(item, index) in categoryList" :key="index">
                <el-option :value="item.id" :label="item.name"></el-option>
              </template>
            </el-select>
          </el-form-item> -->

          <el-form-item label="申请人姓名">
            <el-input v-model="queryForm.likeName" clearable></el-input>
          </el-form-item>

          <el-form-item label="联系方式">
            <el-input v-model="queryForm.likePhone" clearable></el-input>
          </el-form-item>

          <el-form-item label="展会名称">
            <el-input
              v-model="queryForm.likeExhibitionName"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        label="申请单号"
        prop="advertisingCode"
        show-overflow-tooltip
      />
      <el-table-column
        label="申请人姓名"
        prop="contactName"
        show-overflow-tooltip
      />
      <el-table-column
        label="联系方式"
        prop="contactPhone"
        show-overflow-tooltip
      />
      <el-table-column
        label="申请人公司"
        prop="contactEnterpriseName"
        show-overflow-tooltip
      />
      <el-table-column
        label="申请人职务"
        prop="contactPosition"
        show-overflow-tooltip
      />
      <el-table-column
        label="展会名称"
        prop="relationEventName"
        show-overflow-tooltip
      />
      <el-table-column label="需要时间  " prop="title" show-overflow-tooltip>
        <template #default="{ row }">
          {{ row.startTime }} - {{ row.endTime }}
        </template>
      </el-table-column>
      <el-table-column label="需求备注" prop="remark" show-overflow-tooltip />
      <el-table-column
        label="申请时间"
        prop="createTime"
        show-overflow-tooltip
      />
      <el-table-column label="审核状态" show-overflow-tooltip>
        <template #default="{ row }">
          <el-select v-model="row.status" @change="statusChange(row)">
            <template v-for="(item, index) in statusList" :key="index">
              <el-option :value="item.key" :label="item.label"></el-option>
            </template>
          </el-select>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" show-overflow-tooltip width="185">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column> -->
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <!-- <edit ref="editRef" @fetch-data="fetchData" /> -->
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    onActivated,
    onDeactivated,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import { getAdvertList, upDateAdvertStatus } from '@/api/advert'
  export default defineComponent({
    name: 'contentManage',
    components: {
      // Edit: defineAsyncComponent(() => import('./components/articleEdit')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: {
          likeExhibitionName: '',
          pageNum: 1,
          pageSize: 10,
          likeName: '',
          likePhone: '',
        },
        statusList: [
          {
            label: '待审核',
            key: 'PENDING_APPROVAL_STATUS',
          },
          {
            label: '审核通过',
            key: 'PASS',
          },
        ],
        total: 0,
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        categoryList: [],
      })

      const handleEdit = (row) => {
        if (row.articleId) {
          proxy.$router.push({
            path: `/contentCenter/articleEdit`,
            query: { id: row.articleId },
          })
          // state.editRef.showEdit(row)
        } else {
          proxy.$router.push({
            path: `/contentCenter/articleEdit`,
            query: { id: 'new' },
          })
        }
      }

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteArticle({ id: row.id })
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getAdvertList(state.queryForm)
        state.list = data.list
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        fetchData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNum = val
        fetchData()
      }

      onActivated(() => {
        fetchData()
        console.log('我执行了')
      })

      const statusChange = async (row) => {
        console.log(row)
        try {
          const data = await upDateAdvertStatus(row.advertisingCode, row.status)
          fetchData()
        } catch (err) {
          fetchData()
        }
      }

      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        Delete,
        Search,
        Plus,
        statusChange,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
